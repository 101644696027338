*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.containers{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.fixed-telegram {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  transition: transform 0.3s ease; 
  z-index: 9999;
  text-decoration: none;
  color: #000;
}

.fixed-telegram:hover {
  transform: translateY(-10px);
  cursor: pointer; 
}

.tg-text {
  margin-right: 10px;
  font-weight: bold;
}

.tg-icon {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #0088cc;
}

.tg-icon:hover {
  color: #005580;
}

.telegram {
  font-size: 24px;
}

@media screen and (max-width:700px) {
  .fixed-telegram{
    padding: 7px;
    bottom: 10px;
  }
  .telegram{
    font-size: 20px;
  }
  .tg-text{
    font-size: 13px;
  }
}
@media screen and (max-width:500px) {
  .fixed-telegram{
    display: none;
  }
}
