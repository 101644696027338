.about-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px 0;
    gap: 1rem;
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 70px;
}
.about-container .about-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}
.about-left .left-title{
    font-size: 3rem;
    color: #000;
    font-weight: bold;
}
.about-left .left-texts{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.about-left .left-texts .left-text{
    color: #555;
    line-height: 1.5;
}
.about-container .about-right{
    width: 50%;
}
.about-right img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

@media screen and (max-width:1200px) {
    .about-left .left-title{
        font-size: 2.5rem;
    }
}
@media screen and (max-width:1000px) {
    .about-container{
        flex-direction: column-reverse;
        padding-bottom: 0px;
    }
    .about-container .about-left{
        width: 95%;
        align-items: center;
        text-align: justify;
    }
    .about-container .about-right{
        width: 95%;
    }
    .about-right img{
        object-fit: fill;
        border-radius: 20px;
        max-height: 400px;
    }
}
@media screen and (max-width:800px) {
    .about-left .left-title{
        font-size: 2.2rem;
    }
    .about-left .left-texts .left-text{
        font-size: 0.9rem;
    }
    .about-right img{
        max-height: 350px;
    }
}
@media screen and (max-width:700px) {
    .about-container{
        margin-bottom: 20px;
    }
    .about-right img{
        max-height: 300px;
    }
    .about-left .left-title{
        font-size: 2rem;
    }
}
@media screen and (max-width:500px) {
    .about-right img{
        max-height: 250px;
    }
}
@media screen and (max-width:400px) {
    .about-container{
        padding-top:20px;
    }
    .about-right img{
        max-height: 200px;
    }
    .about-left .left-texts .left-text{
        font-size: 0.8rem;
    }
    .about-left .left-title{
        font-size: 1.8rem;
    }
}
@media screen and (max-width:320px) {
    .about-right img{
        max-height: 170px;
    }
    .about-left .left-title{
        font-size: 1.5rem;
    }
    .about-left .left-texts .left-text{
        font-size: 0.7rem;
    }
}