.category-section{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    gap: 20px;
    max-width: 1920px;
    margin: 100px auto 0 auto;
}
.category-left{
    display: flex;
    width: 25%;
    flex-direction: column;
    gap: 2rem;
}
.category-left .search-input-container{
    width: 100%;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    justify-content: space-between;
    max-height: 50px;
    padding: 10px;
    border-radius: 20px;
}
.search-input-container .search-input{
    width: 70%;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border:none;
    background: none;
}
.search-input-container .icon-con{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ff523b;
    color: #fff;
    font-size: 15px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.category-dropdown{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.category-dropdown .category-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.category-title .title-collection{
    font-size: 20px;
    font-weight: 600;
    color: #333;
}
.category-items{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
}
.category-items.active{
    display: none;
}
.category-items .category-link{
    text-decoration: none;
    color: #777;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 18px;
    cursor: pointer;
}
/* right category */
.category-right{
    display: flex;
    width: 70%;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
}
.category-right .right-title{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.right-title .collection-length{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.collection-length span:first-child{
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
}
.collection-length span:last-child{
    color: #777;
}
.right-title .right-buttons-contact{
    padding: 12px 20px;
    color: #fff;
    background: #000;
    appearance: none;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    border: none;
}
.right-cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}
.right-card{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
}
.right-card:hover .right-card-text{
    color: #ff523b;
}
.right-card .right-card-text{
    font-size: 20px;
    font-weight: 600;
    transition: 0.5s;
}
.right-card .right-card-img{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.right-card .right-card-img:hover{
    scale: 1.05;
}
.right-card .right-card-img img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.cards-length{
    width: 40%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}
.cards-length .length-item{
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    width: 40px;
    height: 40px;
    background: #ff523b;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items:center;
    transition: 0.5s;
}
.cards-length .link-item:hover{
    background: #333;
}
@media screen and (max-width:1200px) {
    .category-section{
        gap: 20px;
    }
    .category-left{
        width: 40%;
    }
    .category-right{
        width: 60%;
    }
    .right-title .collection-length .uzunlik{
        font-size: 25px;
    }
    .right-title .right-buttons-contact{
        padding: 10px 15px;
    }
    .right-cards{
        grid-template-columns: repeat(3,1fr);
    }
    .right-card .right-card-img{
        height: 170px;
    }
    .right-card .right-card-text{
        font-size: 18px;
    }
}
@media screen and (max-width:1000px) {
    .category-section{
        flex-direction: column;
        gap: 40px;
    }
    .category-left{
        width: 100%;
    }
    .search-input-container{
        width: 100%;
    }
    .search-input{
        width: 80%;
        padding: 7px 10px;
    }
    .icon-con{
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
    .category-dropdown{
        gap: 20px;
    }
    .category-items{
        flex-direction: row;
        justify-content: space-between;
    }
    .category-right{
        width: 100%;
    }
    .right-card .right-card-img{
        height: 220px;
    }
}
@media screen and (max-width:800px) {
    .right-cards{
        grid-template-columns: repeat(2,1fr);
    }
    .right-card .right-card-img{
        height: 200px;
    }
    .icon-con{
        width: 35px;
        height: 35px;
    }
}
@media screen and (max-width:700px) {
    .category-items .category-link{
        font-size: 15px;
        letter-spacing: 0px;
    }
}
@media screen and (max-width:500px) {
    .category-items .category-link{
        font-size: 12px;
    }
    .right-cards{
        grid-template-columns: repeat(1,1fr);
    }
    .right-card .right-card-img{
        height: 250px;
    }
    .right-card .right-card-text{
        font-size: 25px;
    }
    .right-title .collection-length .uzunlik{
        font-size: 20px;
    }
    .right-title .right-buttons-contact{
        padding: 8px 12px;
        font-size: 12px;
    }
}
@media screen and (max-width:400px) {
    .right-card .right-card-img{
        height: 180px;
    }
    .right-card .right-card-text{
        font-size: 18px;
    }
    .category-items{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .right-title .collection-length{
        gap: 1px;
    }
}
@media screen and (max-width:320px) {
    .category-items .category-link{
        font-size: 10px;
    }
    .right-title .collection-length .uzunlik{
        font-size: 15px;
        letter-spacing: 0px;
    }
}